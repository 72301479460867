@import "../../../assets/scss/base";

.trafficLight {
  background-color: black;
  width: 95px;
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  border-radius: 10px;
}
.trafficLight span {
  width: 75px;
  height: 75px;
  border-radius: 100%;
  background-color: gray;
  /* animation: red 5s linear infinite */
  /* animation-fill-mode: forwards; */
}

.trafficLight.wait span {
  background-color: gray;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  /* animation: red 5s linear infinite */
  /* animation-fill-mode: forwards; */
}

.trafficLight span.red.active {
  background-color: red;
}
.trafficLight span.yellow.active {
  background-color: yellow;
}
.trafficLight.wait span.yellow {
  animation-name: yellow-wait;
}
.trafficLight span.green.active {
  background-color: green;
}

@keyframes yellow-wait {
  0%,
  1% {
    background-color: gray;
  }
  2%,
  51% {
    background-color: yellow;
  }
  52%,
  100% {
    background-color: gray;
  }
}
