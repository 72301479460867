@import "../../../../assets/scss/base";

div.blockArrowContainer {
  position: relative;
  width: auto;
  height: 140px;
 }

ul.blockArrowUl li {
  position: relative;
  text-indent: 30px;
  height: 140px;
  background-color: #767676;
  display: inline-block;
  zoom: 1;
  margin-left: 30px;
  padding: 10px 10px 10px 30px;
  color: white;
  font-size: 18px;
  text-align: center;
  line-height: 130px;
  flex: 1 1 0%;
}

ul.blockArrowUl {
  list-style: none;
}

ul.blockArrowUl li.pendingStatus:first-child,
ul.blockArrowUl li.runningStatus:first-child,
ul.blockArrowUl li.successStatus:first-child,
ul.blockArrowUl li.failedStatus:first-child {
  padding-left: 10px;
  margin-left: 0;
}

ul.blockArrowUl li.pendingStatus:last-child,
ul.blockArrowUl li.runningStatus:last-child,
ul.blockArrowUl li.successStatus:last-child,
ul.blockArrowUl li.failedStatus:last-child {
  padding-right: 40px;
}

ul.blockArrowUl li.pendingStatus:before,
ul.blockArrowUl li.runningStatus:before,
ul.blockArrowUl li.successStatus:before,
ul.blockArrowUl li.failedStatus:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 0;
  height: 0;
  left: 0;
  border-top: 70px solid transparent;
  border-left: 30px solid white;
  border-bottom: 70px solid transparent;
  margin: -10px 0px 0 0px; 
}
.mf-panel ul.blockArrowUl li.pendingStatus:before,
.mf-panel ul.blockArrowUl li.runningStatus:before,
.mf-panel ul.blockArrowUl li.successStatus:before,
.mf-panel ul.blockArrowUl li.failedStatus:before {
  border-left: 30px solid white;
}
.dark .mf-panel ul.blockArrowUl li.pendingStatus:before,
.dark .mf-panel ul.blockArrowUl li.runningStatus:before,
.dark .mf-panel ul.blockArrowUl li.successStatus:before,
.dark .mf-panel ul.blockArrowUl li.failedStatus:before {
  border-left: 30px solid rgb(17, 24, 39);
}
.dark ul.blockArrowUl li.pendingStatus:before,
.dark ul.blockArrowUl li.runningStatus:before,
.dark ul.blockArrowUl li.successStatus:before,
.dark ul.blockArrowUl li.failedStatus:before {
  border-left: 30px solid rgb(31, 41, 55);
}
ul.blockArrowUl li.pendingStatus:first-child:before, 
ul.blockArrowUl li.runningStatus:first-child:before, 
ul.blockArrowUl li.successStatus:first-child:before, 
ul.blockArrowUl li.failedStatus:first-child:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 0;
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
  margin: 0; 
}

ul.blockArrowUl li.pendingStatus:after, 
ul.blockArrowUl li.runningStatus:after, 
ul.blockArrowUl li.successStatus:after, 
ul.blockArrowUl li.failedStatus:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    border-top: 70px solid transparent;
    border-left: 30px solid #767676;
    border-bottom: 70px solid transparent;
    margin: -10px 90px 0 10px; 
}
ul.blockArrowUl li.pendingStatus:last-child:after,
ul.blockArrowUl li.runningStatus:last-child:after,
ul.blockArrowUl li.successStatus:last-child:after,
ul.blockArrowUl li.failedStatus:last-child:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
  margin: 0; 
}

ul.blockArrowUl li.runningStatus {
  background-color: #4F46E5; 
}
ul.blockArrowUl li.runningStatus:after {
  border-left: 30px solid #4F46E5;
}

ul.blockArrowUl li.successStatus {
  background-color: #77a942; 
}
ul.blockArrowUl li.successStatus:after {
  border-left: 30px solid #77a942;
}

ul.blockArrowUl li.failedStatus {
  background-color: #c42c00; 
}
ul.blockArrowUl li.failedStatus:after {
  border-left: 30px solid #c42c00; 
}
